@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiPickerStaticWrapper-root .Mui-selected {
  background-color: #282666!important;
}

.MuiPickerStaticWrapper-root [class$='MuiPickerStaticWrapper-content'] {
  background-color: transparent!important;
}

.MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content {
  background-color: transparent!important;
}

.MuiPickerStaticWrapper-root .MuiPickersDay-root {
  line-height: inherit!important;
}

@media (prefers-color-scheme: dark) {

  .MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content {
    background-color: transparent!important;
  }

  .MuiPickerStaticWrapper-root .MuiButtonBase-root {
    background-color: #111!important;
    color:white!important
  }
  .MuiPickerStaticWrapper-root .Mui-selected {
    background-color: #918abf!important;
  }
  .MuiPickerStaticWrapper-root .Mui-disabled {
    background-color: #2c2c2c!important;
  }
  .MuiPickerStaticWrapper-root .MuiPickersDay-today {
    border-color: #fff!important
  }
  .MuiPickerStaticWrapper-root .MuiDayPicker-weekDayLabel {
    color: #fff!important;
  }
}

@layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
}